import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/blog/PaginatedPosts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/blog/PostDate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/global/modules/footer/FooterWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/vercel/path0/components/global/modules/header/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/global/modules/SetNavbarTextColor.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IKLink","Card","CardHeader","CardBody"] */ "/vercel/path0/components/shared/material/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
